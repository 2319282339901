import React from 'react'
import useWidth from 'src/hooks/window/useWidth'

import Layout from 'src/layouts/BaseLayout'
import HeroChequeEspecial from '../cheque-especial-sucesso/sections/hero/_index'

import pageContext from './pageContext.json'
import { WIDTH_MD } from 'src/styles/breakpoints'

const ChequeEspecialErro = () => {
  const width = useWidth()

  const urlMobileErro = 'https://bancointer.go.link/chequeEspecial?adj_t=1bejxazu&adj_adgroup=contadigital&adj_creative=onboarding_chequeespecial_erro&adj_fallback=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_erro&adj_redirect_macos=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_erro'
  const urlDesktopErro = 'https://ajuda.inter.co/?_gl=1*1tjkq26*_gcl_au*OTY3NjkxNjI3LjE3MTE1NDQyOTI'

  return (
    <Layout pageContext={pageContext}>
      <HeroChequeEspecial
        title='Houve um erro por aqui'
        subTitle='Não foi possível realizar a contratação do Cheque Especial. Tente novamente mais tarde.'
        setIcon='close'
        url={width < WIDTH_MD ? urlMobileErro : urlDesktopErro}
        isPage='erro'
      />
    </Layout>
  )
}

export default ChequeEspecialErro
